import { Component, Emit, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import EventCategoriesModule from '~/app/core/store/modules/EventCategoriesModule';
import { Button, InputField, Multiselect } from '~/components/atoms';
import { ItemData } from '~/components/atoms/select/Select';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import { VueComponentMixin } from '~/utils/vue-component';
import { format, parse } from '~/utils/date-fns';

import style from './EventtiaFilter.scss';
import { defaultDateFormat } from '~/utils/dateTime';
import { ThemeColors } from '~/utils/theme';
import { getLocaleFromRouter } from '~/app/core/router';

const rootClass = 'czt-eventtia-filter';

export type FilterEmit = FilterData;

interface FilterData {
  from: string | null;
  to: string | null;
  packages: string[];
  themes: string[];
  types: string[];
}

interface PresetData {
  from?: string;
  packages?: string[];
  to?: string;
  themes?: string[];
  types?: string[];
}

interface EventtiaFilterInterface {
  filterPreset?: PresetData;
  loading?: boolean;
  onFilter?: (data: FilterEmit) => void;
}

@Component({
  style,
})
export default class EventtiaFilter
  extends VueComponentMixin<EventtiaFilterInterface, PrefetchComponent>(
    Prefetch
  )
  implements EventtiaFilterInterface {
  @Prop()
  public filterPreset?: PresetData;

  @Prop({ default: false, type: Boolean })
  public loading!: boolean;

  protected from: string | null = null;
  protected to: string | null = null;
  protected packages: string[] = [];
  protected themes: string[] = [];
  protected types: string[] = [];

  protected fromMenuState: boolean = false;
  protected toMenuState: boolean = false;

  protected get categoryModule(): EventCategoriesModule {
    return getModule(EventCategoriesModule, this.$store);
  }

  protected get packageOptions(): ItemData[] {
    return this.categoryModule.packages;
  }

  protected get themeOptions(): ItemData[] {
    return this.categoryModule.themes;
  }

  protected get typeOptions(): ItemData[] {
    return this.categoryModule.types;
  }

  protected get formattedFrom(): string {
    if (this.from && this.from !== '') {
      return format(parse(this.from), defaultDateFormat);
    }
    return '';
  }

  protected get formattedTo(): string {
    if (this.to && this.to !== '') {
      return format(parse(this.to), defaultDateFormat);
    }
    return '';
  }

  public prefetch() {
    const eventtiaCategoryModule: EventCategoriesModule = getModule(
      EventCategoriesModule,
      this.$store
    );
    if (
      eventtiaCategoryModule.packages.length > 0 &&
      eventtiaCategoryModule.themes.length > 0 &&
      eventtiaCategoryModule.types.length > 0
    ) {
      return Promise.resolve();
    }
    const locale: string = getLocaleFromRouter(this.$router);
    return Promise.all([
      eventtiaCategoryModule.getPackages(locale),
      eventtiaCategoryModule.getThemes(locale),
      eventtiaCategoryModule.getTypes(locale),
    ]);
  }

  public created() {
    if (this.filterPreset) {
      this.to = this.filterPreset.to || null;
      this.from = this.filterPreset.from || null;
      this.packages = this.filterPreset.packages || [];
      this.themes = this.filterPreset.themes || [];
      this.types = this.filterPreset.types || [];
    }
  }

  public render() {
    return (
      <v-sheet class={`${rootClass} mb-3`} color={ThemeColors.PRIMARY}>
        <form onSubmit={this.emitFilter}>
          <v-col class='py-0'>
            <v-row>
              <v-col class='py-0'>
                <v-row no-gutters>
                  <v-col cols='12'>
                    <v-row>
                      <v-col cols='12' sm='6'>
                        <v-menu
                          close-on-content-click={false}
                          offset-y
                          nudge-left={25}
                          min-width='auto'
                          max-width='300px'
                          scopedSlots={{
                            activator: (data: any) => {
                              return (
                                <div
                                  {...{
                                    on: data.on,
                                  }}
                                >
                                  <InputField<string>
                                    class={`${rootClass}__input`}
                                    value={this.formattedFrom}
                                    read-only
                                    clearable
                                    onInput={(value) => {
                                      if (!value || value === '') {
                                        this.from = null;
                                      }
                                    }}
                                    placeholder={this.$t(
                                      'app.events.placeholder.dateFrom'
                                    )}
                                  />
                                </div>
                              );
                            },
                          }}
                          transition='scale-transition'
                          v-model={this.fromMenuState}
                        >
                          <v-date-picker
                            onChange={(value: string) => {
                              this.from = value;
                              this.fromMenuState = false;
                            }}
                            no-title
                            value={this.from}
                          />
                        </v-menu>
                      </v-col>
                      <v-col cols='12' sm='6'>
                        <v-menu
                          close-on-content-click={false}
                          offset-y
                          nudge-left={25}
                          min-width='auto'
                          max-width='300px'
                          scopedSlots={{
                            activator: (data: any) => {
                              return (
                                <div
                                  {...{
                                    on: data.on,
                                  }}
                                >
                                  <InputField<string>
                                    class={`${rootClass}__input`}
                                    value={this.formattedTo}
                                    read-only
                                    clearable
                                    onInput={(value) => {
                                      if (!value || value === '') {
                                        this.to = null;
                                      }
                                    }}
                                    placeholder={this.$t(
                                      'app.events.placeholder.dateTo'
                                    )}
                                  />
                                </div>
                              );
                            },
                          }}
                          transition='scale-transition'
                          v-model={this.toMenuState}
                        >
                          <v-date-picker
                            onChange={(value: string) => {
                              this.to = value;
                              this.toMenuState = false;
                            }}
                            no-title
                            value={this.to}
                          />
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols='12'>
                    <v-row>
                      <v-col cols='12' md='4'>
                        <Multiselect
                          items={this.typeOptions}
                          v-model={this.types}
                          placeholder={this.$t('app.events.placeholder.type')}
                          loading={this.categoryModule.typesLoading}
                        />
                      </v-col>
                      <v-col cols='12' md='4'>
                        <Multiselect
                          items={this.themeOptions}
                          v-model={this.themes}
                          placeholder={this.$t('app.events.placeholder.theme')}
                          loading={this.categoryModule.themesLoading}
                        />
                      </v-col>
                      <v-col cols='12' md='4'>
                        <Multiselect
                          items={this.packageOptions}
                          v-model={this.packages}
                          placeholder={this.$t(
                            'app.events.placeholder.package'
                          )}
                          loading={this.categoryModule.packagesLoading}
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols='12' md='auto' class='text-center'>
                <Button
                  loading={
                    this.loading ||
                    this.categoryModule.typesLoading ||
                    this.categoryModule.themesLoading ||
                    this.categoryModule.packagesLoading
                  }
                  submit
                >
                  {this.$t('app.events.filter')}
                </Button>
              </v-col>
            </v-row>
          </v-col>
        </form>
      </v-sheet>
    );
  }

  @Emit('filter')
  protected emitFilter(e?: Event): FilterEmit {
    if (e) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
    return {
      from: this.from,
      to: this.to,
      packages: this.packages,
      themes: this.themes,
      types: this.types,
    };
  }
}
