import { Component, Prop } from 'vue-property-decorator';
import { Button, Headline } from '~/components/atoms';
import { Align } from '~/components/atoms/headline/Headline';
import { ImageTileInterface } from '~/components/molecules/imageTile/ImageTile';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import { ThemeColors } from '~/utils/theme';
import { VueComponentMixin } from '~/utils/vue-component';
import { CztWidgets } from '~/utils/views/widgets';

import style from './EventtiaList.scss';
import { getModule } from 'vuex-module-decorators';
import RouterModule from '~/app/core/store/modules/RouterModule';
import { ImageFilterMixinInterface } from '~/mixins';
import { isEventtiaEventsWidget } from '~/app/core/apiClient';
import EventGridItem from '~/components/molecules/gridItem/Event';
import { createEventtiaListItemFromEventtiaEventPage } from '~/utils/views/components';
import EventtiaFilter, { FilterEmit } from '../eventtiaFilter/EventtiaFilter';
import { format, parse } from '~/utils/date-fns';
import { defaultEventFilterDateFormat } from '~/utils/dateTime';
import { PageLoader } from '~/components/templates/common';

export type EventtiaListItem = ImageTileInterface &
  ImageFilterMixinInterface & {
    description: string;
    id: string;
    title: string;
    virtual: boolean;
    uri: string;
    address?: string;
    coords?: Coordinates;
    date?: string;
  };

interface Coordinates {
  lat: number;
  lng: number;
}

interface EventRequestQuery {
  startDate?: string;
  endDate?: string;
  packageGuids?: string;
  themeGuids?: string;
  typeGuids?: string;
}

export interface EventtiaListInterface {
  buttonText?: string;
  className: CztWidgets;
  guid: string;
  isBottomSpacingCollapsed?: boolean;
  isTopSpacingCollapsed?: boolean;
  items: EventtiaListItem[];
  link?: string;
  maxItems?: number;
  nextPageCount?: number;
  pageSize?: number;
  title: string;
}

export function isEventtiaList(data: any): data is EventtiaListInterface {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.EVENTTIA_EVENTS &&
    typeof data.guid === 'string'
  );
}

const rootClass = 'czt-eventtia-list';

@Component({
  style,
})
export default class EventtiaList
  extends VueComponentMixin<EventtiaListInterface, PrefetchComponent>(Prefetch)
  implements EventtiaListInterface {
  @Prop({ required: true, type: Array })
  public items!: EventtiaListItem[];

  @Prop({ required: true, type: String })
  public title!: string;

  @Prop({ required: true, type: String })
  public guid!: string;

  @Prop({ default: false })
  public isBottomSpacingCollapsed!: boolean;

  @Prop({ default: false })
  public isTopSpacingCollapsed!: boolean;

  @Prop({ type: String })
  public link?: string;

  @Prop()
  public overlayColor?: ThemeColors;

  @Prop({ default: 0, type: Number })
  public maxItems!: number;

  /**
   * This is the number of items in the next page that came with initial request
   */
  @Prop({ default: 0, type: Number })
  public nextPageCount!: number;

  @Prop({ default: 0, type: Number })
  public pageSize!: number;

  @Prop({ type: String })
  public buttonText?: string;

  public className = CztWidgets.EVENTTIA_EVENTS;

  /**
   * Extra property for storage of items loaded through load more button
   * as we cannot update the parent 'items' prop
   *
   * These items are concatenated in itemsCollection getter
   */
  protected loadedItems: EventtiaListItem[] = [];

  /**
   * We are starting on page 1
   */
  protected pageNumber: number = 1;

  /**
   * Flag indicating we are downloading items from the backend
   */
  protected fetchingItems: boolean = false;

  /**
   * Whether it is possible to load more items in the eventtia
   */
  protected nextPage: boolean = true;

  protected filterData: FilterEmit = {
    to: null,
    from: null,
    packages: [],
    themes: [],
    types: [],
  };

  protected get routerModule(): RouterModule {
    return getModule(RouterModule, this.$store);
  }

  public created() {
    if (this.nextPageCount === 0) {
      this.nextPage = false;
    }

    this.loadedItems = this.items;
  }

  public render() {
    return <v-sheet class={rootClass}>{this.renderContent()}</v-sheet>;
  }

  protected renderContent(): JSX.Element {
    const containerClasses = ['czt-spacer'];

    if (this.isTopSpacingCollapsed) {
      containerClasses.push('czt-spacer--collapse-top');
    }
    if (this.isBottomSpacingCollapsed) {
      containerClasses.push('czt-spacer--collapse-bottom');
    }

    return (
      <v-container class={containerClasses.join(' ')}>
        <v-row>{this.getHeadline()}</v-row>
        <EventtiaFilter onFilter={this.filter} loading={this.fetchingItems} />
        <v-row class='ma-0 pa-0'>
          <v-col class='py-0'>
            <v-row class={`${rootClass}__container`}>
              {this.getEventtiaItems()}
            </v-row>
            {this.renderLoadMoreButton()}
          </v-col>
        </v-row>
      </v-container>
    );
  }

  protected getHeadline(): JSX.Element | undefined {
    if (this.items.length === 0) {
      return;
    }

    return (
      <v-col class='py-0'>
        <Headline underscore align={Align.LEFT} level={2}>
          {this.title}
        </Headline>
      </v-col>
    );
  }

  protected getEventtiaItems(): JSX.Element | JSX.Element[] {
    if (this.loadedItems.length < 1) {
      if (this.fetchingItems) {
        return (
          <v-col cols='12'>
            <PageLoader />
          </v-col>
        );
      }

      return (
        <v-col cols='12'>
          <Headline align={Align.CENTER} level={3}>
            {this.$t('app.events.noResults')}
          </Headline>
        </v-col>
      );
    }

    return this.loadedItems.map((item) => <EventGridItem item={item} />);
  }

  protected renderLoadMoreButton(): JSX.Element | undefined {
    if (!this.nextPage) {
      // Do not render the load more button if paging is disabled or if there is no next page
      return;
    }

    return (
      <v-row no-gutters justify='center' class='py-3'>
        <Button
          disabled={!this.nextPage}
          loading={this.fetchingItems}
          onClick={() => {
            if (this.fetchingItems) {
              return;
            }

            this.pageNumber++;
            this.getItems();
          }}
        >
          {this.$t('app.common.loadMore')}
        </Button>
      </v-row>
    );
  }

  protected filter(data: FilterEmit): void {
    new Promise((resolve) => {
      this.filterData.from = data.from;
      this.filterData.to = data.to;
      this.filterData.packages = data.packages;
      this.filterData.themes = data.themes;
      this.filterData.types = data.types;
      this.loadedItems = [];
      this.pageNumber = 1;
      this.nextPage = false;
      resolve(undefined);
    }).then(this.getItems);
  }

  protected getItems(): void {
    this.fetchingItems = true;
    const query: EventRequestQuery = {};
    if (this.filterData.from !== null) {
      query.startDate = format(
        parse(this.filterData.from),
        defaultEventFilterDateFormat
      );
    }
    if (this.filterData.to !== null) {
      query.endDate = format(
        parse(this.filterData.to),
        defaultEventFilterDateFormat
      );
    }
    if (this.filterData.packages.length > 0) {
      query.packageGuids = this.filterData.packages.join('|');
    }
    if (this.filterData.themes.length > 0) {
      query.themeGuids = this.filterData.themes.join('|');
    }
    if (this.filterData.types.length > 0) {
      query.typeGuids = this.filterData.types.join('|');
    }

    this.$api
      .widgets()
      .widgetsGetWidgetByGuid(
        this.guid,
        this.pageNumber,
        this.routerModule.resource
          ? this.routerModule.resource.guid
          : undefined,
        this.$i18n.locale,
        CztWidgets.EVENTTIA_EVENTS,
        undefined,
        undefined,
        {
          query,
        }
      )
      .then((widget) => {
        if (isEventtiaEventsWidget(widget)) {
          if (widget.items && widget.items.length > 0) {
            widget.items.forEach((item) => {
              this.loadedItems.push(
                createEventtiaListItemFromEventtiaEventPage(item)
              );
            });

            if (
              !widget.pageCount ||
              !widget.pageNum ||
              widget.pageCount <= widget.pageNum
            ) {
              this.nextPage = false;
            } else {
              this.nextPage = true;
            }
          } else {
            this.nextPage = false;
          }
        }
      })
      .catch(() => {
        this.pageNumber--;
      })
      .finally(() => {
        this.fetchingItems = false;
      });
  }
}
