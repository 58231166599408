import { BaseWidget, GalleryWidget } from '~/app/core/apiClient/api';
import {
  isCarouselWidget,
  isCertifiedSubjectsWidget,
  isEventtiaEventsWidget,
  isGalleryWidget,
  isGridWidget,
  isHtmlWidget,
  isIframeWidget,
  isNewsletterWidget,
  isPanoramaWidget,
  isSafeTravelsWidget,
  isSliderWidget,
  isTabsWidget,
  isVenueFinderWidget,
} from '~/app/core/apiClient';
import {
  createCarousel,
  createCertifiedSubjects,
  createContentBlock,
  createEventtiaListWidget,
  createGallery,
  createGridList,
  createIframeBlock,
  createNewsletterForm,
  createPanorama,
  createSafeTravels,
  createSlider,
  createTabs,
  createVenueFinder,
} from '~/utils/views/components';
import { GridListInterface } from '~/components/organisms/gridList/GridList';
import { CarouselInterface } from '~/components/organisms/carousel/Carousel';
import { ContentBlockInterface } from '~/components/organisms/contentBlock/ContentBlock';
import {
  SliderInterface,
  SliderItemInterface,
} from '~/components/organisms/slider/Slider';
import { GalleryInterface } from '~/components/organisms/gallery/Gallery';
import { GalleryItemInterface } from '~/components/molecules/galleryItem/GalleryItem';
import { TabsWidgetInterface } from '~/components/templates/common/TabsWidget';
import { SearchFormInterface } from '~/components/organisms/searchForm/SearchForm';
import { NewsletterFormInterface } from '~/components/templates/common/NewsletterForm';
import WidgetStyleEnum = GalleryWidget.WidgetStyleEnum;
import { EventtiaListInterface } from '~/components/organisms/eventtiaList/EventtiaList';
import { CertifiedSubjectsListInterface } from '~/components/templates/certifiedSubjects/CertifiedSubjectsList';

export enum CztWidgets {
  CAROUSEL = 'CZT.CarouselWidget',
  CAROUSEL_ITEM = 'CZT.CarouselWidgetItem',
  CERTIFIED_SUBJECTS = 'CZT.CertifiedSubjectsWidget',
  EVENTTIA_EVENTS = 'CZT.EventtiaEventsWidget',
  GALLERY = 'CZT.GalleryWidget',
  GRID = 'CZT.GridWidget',
  GRID_ITEM = 'CZT.GridWidgetItem',
  HTML = 'CZT.HtmlContentWidget',
  IFRAME = 'CZT.IframeWidget',
  MEDIA = 'Media.File',
  NEWSLETTER = 'CZT.NewsletterFormWidget',
  SAFE_TRAVELS = 'CZT.SafeTravelsWidget',
  SEARCH = 'CZT.SearchPlaceholder',
  SLIDER = 'CZT.SliderWidget',
  SLIDER_ITEM = 'CZT.SliderWidgetItem',
  SOCIAL = 'CZT.SocialChannelWidget',
  PANORAMA = 'CZT.PanoramicPhotoWidget',
  TABS = 'CZT.TabbedContentWidget',
  VENUE_FINDER = 'CZT.VenueFinderWidget',
}

export type Widget =
  | CarouselInterface
  | CertifiedSubjectsListInterface
  | ContentBlockInterface
  | EventtiaListInterface
  | GalleryInterface
  | GridListInterface
  | SearchFormInterface
  | SliderInterface
  | TabsWidgetInterface
  | NewsletterFormInterface;

/**
 * Typeguard to filter out null widgets that could not be matched
 * @param item
 */
function notEmptyWidget<TItem>(item: TItem | null): item is TItem {
  return item !== null;
}

export function createWidgets(widgets: BaseWidget[]): Widget[] {
  const mappedWidgets = widgets.map((widget) => {
    switch (true) {
      case isHtmlWidget(widget):
        return createContentBlock(widget);
      case isCarouselWidget(widget):
        return createCarousel(widget);
      case isGridWidget(widget):
        return createGridList(widget);
      case isIframeWidget(widget):
        return createIframeBlock(widget);
      case isSliderWidget(widget):
        return createSlider(widget);
      case isTabsWidget(widget):
        return createTabs(widget);
      case isGalleryWidget(widget):
        return createGallery(widget);
      case isEventtiaEventsWidget(widget):
        return createEventtiaListWidget(widget);
      case isSafeTravelsWidget(widget):
        return createSafeTravels(widget);
      case isPanoramaWidget(widget):
        return createPanorama(widget);
      case isVenueFinderWidget(widget):
        return createVenueFinder(widget);
      case isCertifiedSubjectsWidget(widget):
        return createCertifiedSubjects(widget);
      case isNewsletterWidget(widget):
        return createNewsletterForm(widget);
    }

    return null;
  });

  return mappedWidgets.filter(notEmptyWidget);
}

function createGalleryItemFromSlide(
  item: SliderItemInterface
): GalleryItemInterface {
  return {
    image: item.backgroundImage,
    showVideoControls: item.showVideoControls,
    subtitle: item.superTitle || '',
    title: item.title,
  };
}

export function createGalleryFromSlider(
  slider: SliderInterface
): GalleryInterface {
  return {
    className: CztWidgets.GALLERY,
    items: slider.items.map(createGalleryItemFromSlide),
    ratio: slider.ratio,
    type: WidgetStyleEnum.Slider,
  };
}
