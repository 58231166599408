import { Component, Prop } from 'vue-property-decorator';
import { ImageInterface } from '~/components/atoms/image/Image';
import { VideoInterface } from '~/components/atoms/video/Video';
import { MediaBackground } from '~/components/molecules';
import { IconText } from '~/components/molecules';
import { IconName } from '~/components/atoms/icons/Icon';
import { ImageFilterMixin, ImageFilterMixinInterface } from '~/mixins';
import { ThemeRatios } from '~/utils/theme';
import { VueComponentMixin } from '~/utils/vue-component';

import style from './GalleryItem.scss';
import { Sizes } from '../imageWrapper/ImageWrapper';

export interface GalleryItemInterface extends ImageFilterMixinInterface {
  icon?: IconName;
  image: ImageInterface;
  ratio?: ThemeRatios;
  subtitle?: string;
  title: string;
  video?: VideoInterface;
  showVideoControls?: boolean;
}

const sizes: Sizes[] = [
  {
    size: 100,
    unit: 'vw',
  },
];

@Component({
  style,
})
export default class GalleryItem
  extends VueComponentMixin<GalleryItemInterface, ImageFilterMixinInterface>(
    ImageFilterMixin
  )
  implements GalleryItemInterface {
  @Prop({ required: true })
  public image!: ImageInterface;

  @Prop({ default: ThemeRatios.auto })
  public ratio!: ThemeRatios;

  @Prop({ type: Array })
  public icon?: IconName;

  @Prop({ required: true, type: String })
  public title!: string;

  @Prop({ type: String })
  public subtitle?: string;

  @Prop()
  public video?: VideoInterface;

  @Prop({ default: false, type: Boolean })
  public showVideoControls!: boolean;

  public render() {
    return (
      <div class='czt-gallery__item'>
        <MediaBackground
          image={this.image}
          imageFilter={this.imageFilter}
          ratio={this.ratio}
          sizes={sizes}
          video={this.video}
          showVideoControls={this.showVideoControls}
        >
          {(() => {
            if (this.title || this.subtitle) {
              return (
                <v-container class='my-0 py-0' fill-height>
                  <v-row class='fill-height' align='end'>
                    <v-col>{this.getItemDescription()}</v-col>
                  </v-row>
                </v-container>
              );
            }
          })()}
        </MediaBackground>
      </div>
    );
  }

  protected getItemDescription(): JSX.Element[] | JSX.Element {
    const description = [
      <div class='czt-gallery__item__title'>{this.title}</div>,
    ];

    if (this.subtitle) {
      description.push(
        <div class='czt-gallery__item__subtitle'>{this.subtitle}</div>
      );
    }

    if (this.icon) {
      return (
        <div class='czt-gallery__item__group'>
          <IconText icon={this.icon} iconSize={this.subtitle ? 2.2 : 1.5}>
            {description}
          </IconText>
        </div>
      );
    }

    return (
      <div class='czt-gallery__item__group'>
        <div class='czt-gallery__item__description'>{description}</div>
      </div>
    );
  }
}
