export default {
  app: {
    events: {
      title: 'Nadcházející události',
      noResults: 'Žádné události',
      filter: 'Filtrovat',
      loading: 'Hledání',
      placeholder: {
        dateFrom: 'Datum od',
        dateTo: 'Datum do',
        type: 'Typ',
        theme: 'Téma',
        package: 'Balíček',
      },
    },
  },
};
